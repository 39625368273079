import form from "@/data/en/from.js";


export default {
    "form": form,
    "yes": "Yes",
    "no": "No",
    "new_phone": "New Phone",
    "not_selected": "Not selected",
    "plan_title": "New Mobile Plan",
    "plan_type_label": "Plan Type",
    "select_province_label": "Select Province",
    "province": "Province",
    "tax_rate": "Tax Rate",
    "monthly": "Monthly",
    "monthly_rate": "Monthly Rate",
    "phone": "Phone",
    "months": "Months",
    "roger_label": "Are you a Rogers Customer?",
    "data_plan_label": "Select Data Plan",
    "select_phone_label": "Select Phone",
    "select_phone_color_label": "Select Color",
    "save_as_pdf": "Save as PDF",
    "data_plan": "Data Plan*",
    "list_price": "Phone List Price",
    "tax": "Tax",
    "tax_small": "tax",
    "for": "for",
    "discount_applied": "discount applied",
    "total": "Total",
    "color": "Color",
    "you_pay_upfornt": "You pay upfront",
    "you_save": "You Save",
    "you_save_monthly": "You Save Monthly",
    "invoice_credit": "Invoice Credit",
    "setup_fee": "Setup Fee",
    "monthly_cost": "Monthly Cost",
    "monthly_discount": "Monthly Discount",
    "effective_rate": "Effective Rate",
    "effective_plan_rate_after_tax": "Effective Plan Rate after tax",
    "data_plan_has": "*Data Plan has",
    "manufacturer_link": "Manufacturer Link",

    //more form
    "form_complete_msg": "Please complete the form below to receive additional information.",
    "app_note": "Who is your current mobile phone plan provider? If your provider is not listed, please select" +
        " Other here and add the provider name in Additional Information below.",
    "additional_form_plan_provider": "Who is your current mobile phone plan provider?",
    "additional_form_plan_provider_note": "This is the phone number being transferred. If more than one phone number under the same account is being transferred, please enter the secondary numbers below in Additional Information.",
    "email": "Email",
    "phone_number": "Phone Number",
    "plan_interested": "How many plans are you interested in?",
    "roger_questions": "Are you currently a Rogers customer?",
    "new_device": "New Device Requested",
    "new_device_help": "Phones with $0 in the name from the phone list are included in the monthly plan charge at no extra cost.",
    "plan_compare": "Plan comparison",
    "plan_compare_help": "If you would like us to do a plan comparison, plus upload a recent invoice. This is not required.",
    "comments": "Comments",
    "additional_information": "Any additional information for us or questions that you have?",
    "submit": "Submit",

    //signup
    "interaction_id_label": "Interaction ID - Rogers ONLY",
    "interaction_id_help": "How to get your Interaction ID",
    "rogers_instructions": "Rogers customers require an Interaction ID number to be able to take advantage of this offer",
    //Sign Up Faq:
    "signup_faq": "Sign Up FAQ:",
    //Rogers Step 1
    "rogers_step_1": "Rogers Step 1",
    "rogers_note_1":"NOTE: When there is more than one line in the account and you are not transferring all the lines please ask Rogers Business Support to make one of the remaining lines the primary line for the account. Likewise if there is a data sharing plan with lines in the account and you are not transferring all the lines please ask Rogers Business Support to remove the data sharing from the line being transferred. Otherwise the transfer request will be denied until these items have been authorized.",
    "rogers_note_2":"You continue to have full control over your phone number and may request to have it transferred or released at any time by contacting Sydian at 416 238 1444 or sweet@sydiangroup.com.",
    "step_1_details": "To join the SAVE plan you need to move your phone number from the consumer side to the corporate account of the buying group manager Sydian Group. Please call into Rogers Business Support <a href='tel:18772743375' style='text-decoration:underline;color:#da291c;' target='_blank'>1-877-274-3375</a> and say <b>Please place a note on my line stating that Sydian Group is taking over ownership of all the lines on my account</b>. Rogers provides a confirmation number while you are on the phone. Please make sure this is an Interaction ID and starts with an I.<br><br>NOTE: When there is more than one line in the account and you are not transferring all the lines please ask Rogers Business Support to make one of the remaining lines the primary line for the account. Likewise if there is a data sharing plan with lines in the account and you are not transferring all the lines please ask Rogers Business Support to remove the data sharing from the line being transferred. Otherwise the transfer request will be denied until these items have been authorized.<br><br>You continue to have full control over your phone number and may request to have it transferred or released at any time by contacting Sydian.<br><br>If there are any issues please contact the buying group manager Sydian Group at <a href='tel:4162381444'>416 238 1444</a> or <a href='mailto:sweet@sydiangroup.com'>sweet@sydiangroup.com</a>.",
    "interaction_part_1": "To join the SAVE plan you need to move your phone number from the consumer side to the corporate account of the buying group manager Sydian Group. Please call into Rogers Business Support 1-877-274-3375 and say <b>Please place a note on my line stating that Sydian Group is taking over ownership of all the lines on my account and please remove this (these) line(s) from any shared plans.</b> Rogers provides a confirmation number while you are on the phone. Please make sure this is an Interaction ID and starts with an I",
    "interaction_part_2": "NOTE: When there is more than one line in the account and you are not transferring all the lines please ask Rogers Business Support to make one of the remaining lines the primary line for the account. Likewise if there is a data sharing plan with lines in the account and you are not transferring all the lines please ask Rogers Business Support to remove the data sharing from the line being transferred. Otherwise the transfer request will be denied until these items have been authorized.",
    "interaction_part_3": "You continue to have full control over your phone number and may request to have it transferred or released at any time by contacting Sydian.",
    "interaction_part_4": "If there are any issues please contact the buying group manager Sydian Group at 416 238" +
        " 1444 or sweet@sydiangroup.com.",
    "copy": "Copy",
    "signup_note": "Your current provider requires us to submit your account number before they will transfer the" +
        " account to Rogers.",
    //Current Mobile Phone Number
    "current_mobile_phone_number": "Phone Number being transferred.",
     "current_mobile_phone_number_note": "This is the phone number being transferred. If more than one phone number under the same account is being transferred, please enter the secondary numbers below in Additional Information.",
    //New plan selected
    "new_plan_selected": "New plan selected",
    "select_plan": "Select Plan",
    "line_1_plan_selected": "Line 1 - Plan Selected",
    "plan_cost": "Plan Cost",
    "phone_upfront_cost": "Phone Upfront Cost",
    "security_deposit": "Security Deposit",
    //Phone to purchase
    "phone_to_purchase": "Phone to purchase",
    "plan_and_phone": "Plan and Phone Information",
    //Monthly invoices will be sent to this email address.
    "monthly_invoices_msg": "Monthly invoices will be sent to this email address.",
    //Contact Phone
    "contact_phone": "Contact Phone",
    "contact_phone_note": "If different than Current Mobile Phone Number above.",
    //Shipping address
    "shipping_address": "Shipping address",
    "shipping_address_note": "Note: Rogers does not ship to PO boxes",
    "shipping_address_note2": "SIM card and\\or new phones are shipped to this address. Rogers does not ship to PO" +
        " Boxes. A signature is required for delivery",
    //City
    "city": "City",
    //Postal Code
    "postal_code": "Postal Code",
    "country": "Country",
    //country_note
    "country_note": " PLEASE NOTE: SIM card and\\or new phones are shipped to this address. A signature is required for delivery.",
    //Billing To: Name
    "billing_to_name": "Billing To: Name",
    "billing_to_name_note": "If Billing Name is different than Name above.",
    //Additional Information
    "signup_additional_information": "Additional Information",
    "signup_additional_information_note": "Add any other information or additional phone numbers that may be\n" +
        "          transferred.",
    //Street Address
    "street_address": "Street Address",
    "summary": "Summary",
    //Address Line 2
    "address_line_2": "Address Line 2",
    'no_plan_selected': 'No plan selected',
    //First Name
    "name": "Name",
    "first_name": "First Name",
    "last_name": "Last Name",
    "current_provider": "Current Provider",
    "billing_name": "Billing Name",
    "additional_lines": "ADD ADDITIONAL LINES",
    "other_information_comment": "Comments? Any other information we should know?",
    "disclaimers": "Disclaimers",
    "add_first_line": "Add First Phone Number to be transferred - Additional lines may be added by clicking Add Additional Lines below",
    "add_first_line1": "Add Phone Number to be transferred",
    "add_first_line2": "Additional lines may be added by clicking Add Additional Lines below",
    "current_account_number": "Line 1 - Current Account Number",
    //Please input the other providers name
    "other_provider_name": "Please input the other providers name",
    //Selected Provider
    "selected_provider": "Selected Provider",
    //Includes Tax
    "includes_tax": "Includes Tax",
    //for the phone
    "for_the_phone": "for the phone",
    //on your phone purchase
    "on_your_phone_purchase": "on your phone purchase",
    //No Phone selected
    "no_phone_selected": "No Phone selected",
    "monthly_plus_tax": "monthly + Tax",
    //Required by your provider before we can transfer
    "required_by_your_provider": "Required by your provider before we can transfer",
    "select_language" : "Select Language",
    "select_phone" : "Select Phone",
    "select_color": "Select Color",
    "line": "Line",
    "phone_selected": "Phone Selected",
    "select_phone_color": "Select Phone Color",
    "byod": "BYOD",
    "choose_file": "Choose File",
    "selected_file": "Selected File",
    //Less than 5
    "less_than_5": "Less than 5",
    //5 or more
    "5_or_more": "5 or more",
    "account_number_error": "Account Number and Phone Number can not be the same. Please find your Account Number on your invoice and enter a new number. Your provider requires us to provide the Account Number when transferring your phone number.",

    'all_customer_title': 'All Customers',
    'rogue_customer_title': 'Rogers Customers Only',
    'all_customer_1': '1. You need the account number and your phone number from your current provider. Your account' +
        ' number is different than your account number and your current provider requires us to submit this number to them before transferring your number.',
    'all_customer_2': '2. Your shipping address can not be a PO box. Rogers requires a signature for delivery. If you' +
        ' have your sim card\\phone shipped to a PO box, it will be returned.',
    'rogers_customer_1': '1. Rogers requires Rogers customers to obtain an Interaction ID to take advantage of this' +
        ' offer. You must obtain the ID to complete the sign up process.',
    'next': 'Next',
    'previous': 'Previous',
    'what_is_it_going_to_cost': 'What’s it going to cost?',
    'complete_the_form': 'Complete the form below to sign up',
    'sign_up': 'Sign Up',
    'before_signing_up': 'Before signing up',
    'cost_calculator': 'Cost Calculator',



}
