<template>
  <input required="required" type="text" checktype="c1"
         v-model="phoneNumber"
         v-on:keyup="onNumberUpdate($event)"
         :readonly="currentProvider === 'None'"
         maxlength="255" fieldType=1/>
</template>

<script>

export default {
  name: 'PhoneNumber',
  data() {
    return {
      phoneNumber: this.currentMobilePhoneNumber,
    }
  },
  props: {
    currentMobilePhoneNumber: String,
    currentProvider: String
  },
  methods: {
    onNumberUpdate(event) {
      let value = event.target.value;
      //first input can't be 1
      if (value.length === 1 && value == '1') {
        value = '';
      }
      //only accept numbers
      if (value.match(/[^0-9]/g)) {
        value = value.replace(/[^0-9]/g, '');
        this.phoneNumber = value;
      }

      //formart tha input value to (xxx) xxx-xxxx

      value = value.replace(/\D/g, '');
      value = value.replace(/^(\d{3})(\d)/g, '($1) $2');
      value = value.replace(/(\d)(\d{4})$/, '$1-$2');
      this.phoneNumber = value;
      //parse only the numbers to the parent component
      let phone = value.replace(/\D/g, '');
      this.$emit('update:currentMobilePhoneNumber', phone);
    }
  },
  watch: {
    currentProvider: function (val) {
      //if none
      if (val === 'None') {
        this.phoneNumber = this.currentMobilePhoneNumber ;
      }else {
        this.phoneNumber = '';
      }
    }
  }
}

</script>
