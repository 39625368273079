import data from "./data.json";
import Phones from "./phones.json";
import province from "./province.json";
import plans from "./plans.json";
import plansbyod from "./plansbyod.json";
import plansphone from "./plansphone.json";


export default {
    'data': data,
    'rogerCustomers': 'No',
    'planType': 'BYOD',
    'selectedProvince': 2,
    'months': data.months,
    'setupFee': data.setup_fee,
    'selectedPlan': '',
    'selectedPhone': '',
    'selectedColor': '',
    'phones': Phones,
    'provinces': province,
    'plans': plans,
    'disclaimers': data.disclaimers,
    'messages': data.messages,
    'pdfData': '',
    'computedData': '',
    'securityDeposit': 0,
    'name': '',
    'currentAccountNumber': '',
    'currentMobilePhoneNumber': '',
    'currentProvider': 'Rogers',
    'currentProviderValue': 'Rogers',
    'integrationId': '',
    'upfront': '0.0',
    'additionalInfo': '',
}
