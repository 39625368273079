<template>
  <div class="row">
    <div class="container">
       <Logo/>
      <div class="row">
        <div class="col-lg-12">

          <div class="signupSec"  v-show="!isSubmitting">
            <div class="formsec">
              <p class="zf-frmDesc">{{ $t('form_complete_msg') }}</p>
              <form
                  action='https://forms.zohopublic.com/virtualoffice9283/form/LeadsSAVEPlans/formperma/2QK4sKCqrXvyDSYBwD8ZUY6FjDsXPpvCp9Pup6kcVkM/htmlRecords/submit'
                  @submit="checkForm"
                  name='form' method='POST'
                  onSubmit='javascript:document.charset="UTF-8"; return zf_ValidateAndSubmit();'
                  accept-charset='UTF-8' enctype='multipart/form-data' id='pardot-form'
                  class="form needs-validation">
                <input type="hidden" name="zf_referrer_name" value="">
                <!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
                <input type="hidden" name="zf_redirect_url" :value="redirectUrl">
                <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
                <input type="hidden" name="zc_gad" value="">
                <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
                <div class="formRow">
                  <label>{{ $t("first_name") }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" maxlength="255" name="Name_First" fieldType=7/>
                </div>
                <div class="formRow">
                  <label>{{ $t("last_name") }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" maxlength="255" name="Name_Last" fieldType=7/>
                </div>
                <div class="formRow">
                  <label>{{ $t("current_provider") }}
                    <span style="color:#da291c!important;"> <span :style="asterisksColor"> *</span> </span>
                  </label>
                  <select required="required" checktype="c1"
                          @change="onProviderChange($event)">
                    <option v-if="!isRogersCustomer()" selected="true" value="">-Select-</option>
                    <option v-for="(provider,idx) in providers" :value="provider" :key="idx">{{ provider }}</option>
                  </select>

                  <input type="hidden" name="SingleLine5" :value="currentProviderValue">

                  <div v-if="currentProvider==='Other'">
                    <label><b>{{ $t('other_provider_name') }}</b>
                      <span :style="asterisksColor"> *</span>
                    </label>
                    <input type="text" value="" required @keyup="updateOtherProvider"
                           fieldType=1 class="form-control">

                    <label>{{ $t('selected_provider') }}
                      <span :style="asterisksColor"> *</span>
                    </label>

                    <i>{{ currentProviderValue }}</i>
                  </div>
                  <small>
                    {{ $t('app_note') }}
                  </small>


                </div>
                <div class="formRow">
                  <label>{{ $t('email') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input fieldType=9 type="email" required="required" maxlength="255" name="Email" checktype="c5"
                         value=""/>
                </div>
                <div class="formRow">
                  <label>{{ $t('phone_number') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="number" compname="PhoneNumber"
                         name="PhoneNumber_countrycode" maxlength="20" checktype="c7" value="" phoneFormat="1"
                         isCountryCodeEnabled=false fieldType="11" id="international_PhoneNumber_countrycode"
                         valType="number" phoneFormatType="1"/>
                </div>
                <input type="hidden" id="Radio1_1" name="Radio1" checktype="c1" :value="form.rogerCustomers">
                <input type="hidden" class="zf-form-sBox" name="SingleLine3" checktype="c1" :value="description">
                <div v-if="isNewPhone" class="formRow">
                  <label>{{ $t('new_device') }}</label>
                  <input type="hidden" name="SingleLine1" checktype="c1" :value="phoneName">
                  <i>{{ phoneName }}</i>
                </div>

                <div class="formRow">
                  <label>{{ $t('plan_compare') }}</label>
                  <button style="display:block; height:30px;"
                          onclick="event.preventDefault();document.getElementById('FileUpload').click()">
                    {{ $t('choose_file') }}
                  </button>
                  <input @change="fileSelected" type="file" id="FileUpload" name="FileUpload" checktype="c1"
                         style="display:none"/>
                  <div v-if="selectedFile">
                    {{ $t('selected_file') }}:
                    <i>{{ selectedFile.name }}</i>
                    <br><br>
                  </div>
                  <small> {{ $t('plan_compare_help') }}</small>
                </div>
                <div class="formRow">
                  <label>{{$t('summary')}}:</label>
                  <textarea @input="resize('lineSummary')" @change="resize('lineSummary')" :value="lineSummary"
                            ref="lineSummary"
                            name="phoneSummary" rows="12"></textarea>
                </div>
                 <div class="formRow">
                  <label>{{ $t('signup_additional_information') }}!</label>
                   <i><div style="white-space: pre-wrap;" v-html="additionalInfo"></div></i>
                </div>
                <div class="formRow">
                  <label>{{ $t('comments') }}?</label>
                  <textarea checktype="c1" maxlength="65535" v-model="comment"></textarea>
                  <small>{{ $t('additional_information') }}</small>
                  <input name="MultiLine" checktype="c1" type="hidden" :value="additionalInfo"/>
                </div>
                <input type="hidden" name="SingleLine4" :value="form.planType" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine2" :value="source" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine" :value="upper+'-SAVE'" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine6" :value="lower" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine7" :value="$i18n.locale" fieldType=1 maxlength="255"/>

                <ShowData v-for="(data, key) in form.disclaimer.data" :key="key" :data="data"></ShowData>
                <div class="textCol">
                  <input type="submit" style=" width: 100%;outline: none;border: 0px solid;text-align:left;
                  padding-left:12px" id='formsubmit' class="next-btn text-left" :title="$t('submit')" :value="$t('submit')">
                  <div class="prev-btn" @click="previousStep">{{ $t('previous') }}</div>
                </div>
              </form>
            </div>
          </div>

          <div v-show="isSubmitting" style="text-align: center">
            Submitting the form, please wait...
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ShowData from "@/components/ShowData";
import Form from "@/components/logics/Form.js";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo,
    ShowData
  },
  extends: Form,
  name: 'MoreForm',
  data() {
    return {
      comment: '',
      isSubmitting: false,
      disclaimer: {},
      currentProvider: 'Rogers',
      currentProviderValue: 'Rogers',
      selectedFile: null,
    };
  },
  created() {
    // this.disclaimer = this.form.pdfData.find(item => item.id === "27,30");
    // console.log(this.disclaimer);
  },
  methods: {
    previousStep() {
      this.$emit('previousStep');
    },
    fileSelected(event) {
      this.selectedFile = event.target.files[0];
    },
    onProviderChange(event) {
      this.currentProvider = event.target.value;
      this.currentProviderValue = this.currentProvider;
    },
    updateOtherProvider(event) {
      if (event.target.value.toLowerCase() === 'rogers') {
        this.form.rogerCustomers = this.$t('yes');
        return;
      }
      this.currentProviderValue = 'Other - ' + event.target.value;
    },
    checkForm(e) {
      console.log('checkForm');

      if (!this.form.selectedPlan) {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("selected_plan").reportValidity();
        e.preventDefault();
      }

      if (this.isNewPhone && !this.form.selectedPhone) {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("selected_phone").reportValidity();
        e.preventDefault();
      }

      if (this.isNewPhone && this.form.selectedColor === '') {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("phone_color").reportValidity();
        e.preventDefault();
      }
      this.isSubmitting = true;

      return true;

    },
    formatMoney(value) {
      return Number(value).toFixed(2).toLocaleString();
    }
  },
  computed: {
    asterisksColor() {
      return {
        color: 'red',
      }
    },
    providers() {
      if (this.isRogersCustomer()) {
        return ['Rogers'];
      } else {
        return ['Bell', 'Telus', 'Freedom', 'Virgin', 'Fido', 'Chatr', 'Public', 'Shaw', 'Koodo', 'Eastlink', 'Other', 'None'];
      }
    },
    redirectUrl() {
      if (this.isNewPhone) {
        return 'https://www.vestanetworks.com/site/' + this.rurlnphone + '/' + this.lower;
      } else {
        return 'https://www.vestanetworks.com/site/' + this.rurlbyod + '/' + this.lower;
      }
    },
    phoneName() {
      if (!this.form.selectedPhone) return this.$t('no_phone_selected');
      //if color selected add color to name
      let selectedPhone = this.form.phones[this.form.selectedPhone - 1];
      let phoneName = selectedPhone.phone;
      if (this.form.selectedColor) {
        phoneName += ' - ' + this.form.selectedColor;
      }
      phoneName += ' - $' + selectedPhone.rogers_list;
      return phoneName;
    },
    description() {
      if (!this.form.selectedPlan) return this.$t('no_plan_selected');
      let selectedPlan = this.form.plans[this.form.selectedPlan - 1];
      if (this.form.planType === this.$t('byod')) {
        return '$' + this.formatMoney(selectedPlan.BYOD) + ' ' + this.$t('monthly_plus_tax') + ' (' + this.$t('byod') + ') - ' + selectedPlan.data + ' ' + selectedPlan.description;
      } else {
        return '$' + this.formatMoney(selectedPlan.with_device) + ' ' + this.$t('monthly_plus_tax') + ' - ' + selectedPlan.data + ' ' +
            selectedPlan.description;
      }

    },
    lower() {
      return document.getElementById('lower').value;
    },
    upper() {
      return document.getElementById('upper').value;
    },
    source() {
      return document.getElementById('source').value;
    },
    additionalInfo() {
      //from calculations add line for upfront cost if new phone type including 0 "You Pay Upfront $0.00 for the phone "
      let additionalInfo = '';
      //return from pdf data where id = 18
      let upfrontArray = this.form.pdfData.find(item => item.id === 18);
      if (!upfrontArray.hide) {
        additionalInfo = upfrontArray.title + ' ' + upfrontArray.value + '\n';
      }

      let discountArray = this.form.pdfData.find(item => item.id === 25);
      if (!discountArray.hide) {
        additionalInfo += discountArray.title + ' ' + discountArray.value + '\n';
      }

      if (this.currentProvider === 'None') {
        additionalInfo += this.$t('security_deposit') + ' of $300 required\n';
      }

      if (this.comment){
        additionalInfo += this.comment + '\n';
      }

      return additionalInfo;
      //add line for monthly discount if applicable "Monthly Discount $(2.39)" do not show if 0)
    },
    rurlbyod() {
      return document.getElementById('rurlbyod').value;
    },
    rurlnphone() {
      return document.getElementById('rurlnphone').value;
    },
    isNewPhone() {
      return this.form.planType === this.$t('new_phone');
    }
  },
  props: {
    form: Object
  },
  watch: {
    'form.rogerCustomers': function (newValue) {
      if (newValue === this.$t('yes')) {
        this.currentProvider = 'Rogers';
        this.currentProviderValue = 'Rogers';
      } else {
        this.currentProvider = '';
        this.currentProviderValue = '';
      }
    },
  }
}
</script>
<style scoped>
select, option {
  font-size: 0.8rem;
}
</style>
